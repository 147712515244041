import PropTypes from "prop-types";

export default function TrackingDownloadLink({ url, targetUrl, text, className, button, elementStyle, onClick }) {
	return (
		<a
			href={url}
			target="_blank"
			className={className}
			onClick={onClick}
			data-element-type={button ? "button" : "textlink"}
			data-element-label={text}
			data-element-target={url ? url : targetUrl}
			data-element-style={elementStyle}
			data-element-action="download"
			rel="noreferrer">
			{text}
		</a>
	);
}

TrackingDownloadLink.propTypes = {
	url: PropTypes.string,
	targetUrl: PropTypes.string,
	text: PropTypes.string,
	button: PropTypes.bool,
	className: PropTypes.string,
	elementStyle: PropTypes.string,
	onClick: PropTypes.func,
};
